/*
NOTE: This file uses Swiper as loaded from npm, which means npm is the "package manager" for it.
      To get a more recent version of Swiper, you need to update it like this:
      `ddev npm update`
*/
import { register } from 'swiper/element/bundle';
register();

/*
* NOTE: This is the easiest way to manage sliders that need to be customised beyond what setting parameters on the web component will allow.
*
* init='false' needs to be set on the component, and then customise things below.
* */

// FEATURED ENTRIES SLIDERS
	const featuredEntrySliders = document.querySelectorAll(`[data-entry-type="rowFeaturedEntriesSlider"] swiper-container[init="false"]`);

	console.log(`We ran and we found ${featuredEntrySliders.length} featuredEntrySliders that need custom styles and behaviours.`);

	featuredEntrySliders.forEach(
		featuredEntrySlider => {
			/* The nav buttons need to be external to the Web Component in order to position them OUTSIDE of it... which is a PITA because we have to link *the right* navigation elements to the slider, here's the faff workaround to do that which needs Twig as well.

				<div data-entry-type="rowFeaturedEntriesSlider">
					<swiper-container
						init="false"
						data-controls="theseControls"    <- This class may need to be dynamic on the Twig if there are multiple sliders!
					>
						...
					</swiper-controls>

					<div class="theseControls">        <- This class may need to be dynamic on the Twig if there are multiple sliders!
						<div class="vcaPrev">Prev</div>
						<div class="vcaPagination"></div>
						<div class="vcaNext">Next</div>
					</div>
				</div>
			*/
			let controlLinkString = featuredEntrySlider.dataset.controls;

			let featuredEntrySliderParams = {
				navigation: {
					prevEl: `.${controlLinkString} .vcaPrev`,
					nextEl: `.${controlLinkString} .vcaNext`,
				},
				pagination: {
					el: `.${controlLinkString} .vcaPagination`,
					type: 'bullets',
					clickable: true
				},
				centerInsufficientSlides: true,
				slidesPerView: 1,
				spaceBetween: 20,
				breakpoints: {
					640: {
						slidesPerView: 2,
					},
					960: {
						slidesPerView: 3,
					},
					1200: {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				},
				injectStyles: [
					`
						swiper-slide {
							height: auto;
						}
					`
				],
			};

			Object.assign(featuredEntrySlider, featuredEntrySliderParams);
			featuredEntrySlider.initialize();
		}
	);
